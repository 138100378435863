import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import FilterBar from "../../components/browser/filter-bar";
import PageContainer from "../../components/layout/page-container";
import PageSection from "../../components/layout/page-section";

class ImagesByArtistPage extends React.Component {
  constructor({ data }) {
    super();

    this.data = data;
  }

  render() {
    const allImageEdges = this.data.allImagesJson.edges;

    let artistEdgesWithImages = this.data.allArtistsJson.edges.filter(({ node }) => {
      const artistReference = node.reference;

      const imagesForArtist = allImageEdges.filter(({ node }) => node.artists.includes(artistReference));
      
      return imagesForArtist.length > 0;
    });

    return (
      <Layout>
        <PageContainer
          bottomPadding="lg"
        >
          <PageSection background="dark-accent">
            <h1 className="page-title">Images by Artist</h1>
          </PageSection>
          <PageSection topPadding="md">
            <FilterBar filterSet="images" currentFilter="artist" />
            <ul className="listing-grid">
              {artistEdgesWithImages.map(({ node }) => (
                <li key={node.reference} className="listing-grid__cell">
                  <Link to={`/images/artist/${node.reference}`}>{node.canonical_name}</Link>
                </li>
              ))}
            </ul>
            <FilterBar filterSet="images" currentFilter="artist" />
          </PageSection>
        </PageContainer>
      </Layout>
    );
  }
}

export default ImagesByArtistPage;

export const query = graphql`
  query ImagesByArtistQuery {
    allArtistsJson(sort: {fields: sort_name}) {
      edges {
        node {
          reference
          canonical_name
          sort_name
        }
      }
    }
    allImagesJson {
      edges {
        node {
          artists
        }
      }
    }
  }
`